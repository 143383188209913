// Packages
import $ from 'jquery';
import gsap from 'gsap';
import {
	ScrambleTextPlugin
} from 'gsap/ScrambleTextPlugin';

// ---- Register GSAP plugins ----
gsap.registerPlugin(ScrambleTextPlugin);

// Init sliders
function navigationInit() {
	// Search
	$('.c-header_nav_search-button').on('click', () => {
		if (!$('.c-header_search').hasClass('is-active')) {
			$('.c-header_search .c-form_input').focus();
		}
		$('.c-header_search').toggleClass('is-active');
		$('.o-mini-cart, .c-header_nav_cart-button').removeClass('is-active');
	});
	// Main reset
	$('main').on('click', () => {
		$('.c-header_search').removeClass('is-active');
		$('.o-mini-cart, .c-header_nav_cart-button').removeClass('is-active');
	});
	// Toggler
	$('.c-header_nav_toggler').on('click', (event) => {
		$(event.currentTarget).toggleClass('is-active');
		$('.c-mobile_nav').toggleClass('is-active');
		$('.c-mobile_nav_socials__wrap').toggleClass('is-active');
	});
	// Mini cart
	$('.c-header_nav_cart-button').on('click', (event) => {
		$(event.currentTarget).toggleClass('is-active');
		$('.o-mini-cart').toggleClass('is-active');
		$('.c-header_search').removeClass('is-active');
	});
	$('body').on('click', '.o-mini-cart .close', () => {
		$('.o-mini-cart, .c-header_nav_cart-button').removeClass('is-active');
	});
	// Subnav
	$('.menu-item-has-children .c-header_nav_link').each(function storeTextValue() {
		$(this).attr('data-text', $(this).text());
	});

	$('.c-header_nav_list .menu-item-has-children').each(function addBackArrow() {
		$(this).prepend('<div class="-back" />');
	});

	$('.c-header_nav_link').each(function fhjsdkafhsdjakl() {
		$(this).wrap('<div class="c-header_nav_link_hover" />');
	});

	$('.menu-item-has-children .c-header_nav_link').on('click', (e) => {
		e.preventDefault();
		e.stopPropagation();

		$(e.currentTarget).parent().parent().toggleClass('is-active');
		if ($('.menu-item').hasClass('is-active')) {
			$('.menu-item .c-header_nav_link').not(e.currentTarget).parent().parent()
				.addClass('hide');
			gsap.to(e.currentTarget, {
				duration: 0.9,
				scrambleText: {
					text: 'Retour',
					chars: 'lowerCase',
					speed: 0.9
				}
			});
		} else {
			$('.menu-item').removeClass('hide');
			gsap.to(e.currentTarget, {
				duration: 0.9,
				scrambleText: {
					text: $(e.currentTarget).data('text'),
					chars: 'lowerCase',
					speed: 0.9
				}
			});
		}
	});

	$('.c-mobile_nav.-alt .c-header_nav_link').on('click', () => {
		$('.c-mobile_nav.-alt').add('.c-mobile_nav_socials__wrap.-alt').add('.-alt .c-header_nav_toggler').removeClass('is-active');
	});
}

// Export functions
module.exports = {
	navigationInit
};
