// Packages
import $ from 'jquery';
import barba from '@barba/core';
import gsap from 'gsap';

// Files
const pagetransition = require('./_transition');
const slider = require('./_slider');
const accordion = require('./_accordion');
const navigation = require('./_navigation');
const scroll = require('./_scroll');
const excludeLinks = require('./_excludeLinks');
const woocommerce = require('./_woocommerce');
const tabs = require('./_tabs');
const video = require('./_video');
const popup = require('./_popup');
const list = require('./_list');
const refreshPlugins = require('./_refreshPlugins');

$(window).on('load', () => {
	const isMobile = (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) || (window.innerWidth <= 1024);

	// GSAP config
	gsap.config({
		nullTargetWarn: false
	});

	// Delay function
	function delay(n) {
		return new Promise((done) => {
			setTimeout(() => {
				done();
			}, n);
		});
	}

	// Barba init & transitions setup
	barba.init({
		sync: true,
		prefetchIgnore: true,
		transitions: [{
			async leave() {
				const done = this.async();
				pagetransition.transitionLeave();
				await delay(1300);
				done();
				$('html, body').scrollTop(0, 0);
			},
			async enter() {
				pagetransition.transitionEnter();
			},
			async once() {
				pagetransition.transitionFirstLoad();
			}
		}]
	});

	// Barba Before leave
	barba.hooks.before(() => {
		// Site is loading
		$('html').removeClass('is-loaded is-ready');
		$('html').addClass('is-loading');
		scroll.destroy();
	});

	// Barba After enter
	barba.hooks.after(() => {
		// Init Exclude Links
		excludeLinks.excludeLinksInit();
		// Give a small delay before reinit external files / functions
		setTimeout(() => {
		// Init Navigation
			navigation.navigationInit();
			// Init Slider
			slider.slidersInit();
			// Init Accordions
			accordion.accordionsInit();
			// Init Woocommerce
			woocommerce.woocommerceInit();
			// Anchor links
			scroll.anchorLinks(isMobile);
			// Tabs
			tabs.tabsInit();
			// Init Video
			video.videoInit();
			// Init Popup
			popup.popupInit();
			// Init List
			list.listInit();
			// Refresh plugins
			refreshPlugins.refreshPlugins();
			// Start Videos on home page
			$('video').get(0).play();
			$('video').get(1).play();
		}, 1400);

		setTimeout(() => {
			$('html').addClass('is-loaded is-ready');
			$('html').removeClass('is-loading');
			$('html').removeAttr('style');
		}, 1300);

		setTimeout(() => {
			scroll.initScroll(isMobile);
		}, 800);
	});

	// Barba After enter
	barba.hooks.afterOnce(() => {
		// Init Exclude Links
		excludeLinks.excludeLinksInit();
		// Give a small delay before reinit external files / functions
		setTimeout(() => {
			// Init Navigation
			navigation.navigationInit();
			// Init Slider
			slider.slidersInit();
			// Init Accordions
			accordion.accordionsInit();
			// Init Woocommerce
			woocommerce.woocommerceInit();
			// Anchor links
			scroll.anchorLinks(isMobile);
			// Tabs
			tabs.tabsInit();
			// Init Video
			video.videoInit();
			// Init Popup
			popup.popupInit();
			// Init List
			list.listInit();
		}, 1700);

		setTimeout(() => {
			$('html').addClass('is-loaded is-ready');
			$('html').removeClass('is-loading');
			$('html').removeAttr('style');
		}, 1600);

		setTimeout(() => {
			scroll.initScroll(isMobile);
		}, 800);
	});

	barba.hooks.once((next) => {
		// Barba cache first page
		barba.cache.set(next.url.href, next.html, 'init');
	});
});
