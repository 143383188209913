// Packages
import $ from 'jquery';

// Refresh Wordpress Plugins
function refreshPlugins() {
	// Fix Woocommerce
	$('script*[src*=woocommerce]').each(function refreshWoocommerce() {
		$(this).remove().appendTo('html');
	});

	// Fix Forms
	$('script*[id*=quform]').each(function () {
		$(this).remove().appendTo('html');
	});

	$('.quform-form').submit(() => {
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 2000);
	});
}

// Export functions
module.exports = {
	refreshPlugins
};
