// Packages
import $ from 'jquery';
import gsap from 'gsap';
import {
	CustomEase
} from 'gsap/CustomEase';

// ---- Register GSAP plugins ----
gsap.registerPlugin(CustomEase);

function removeSoluceLogo() {
	$('.o-firstload').remove();
}

function removeSectionImages() {
	$('.o-section_images').remove();
}

// function fakeResize() {
// 	window.dispatchEvent(new Event('resize'));
// }

// First load transition
function transitionFirstLoad() {
	// logo
	gsap.to('.o-firstload_logo__wrap', {
		clipPath: 'inset(0% 0% 0% 0%)',
		duration: 1.3,
		delay: 0.3,
		ease: CustomEase.create('custom', 'M0,0,C0.084,0.61,0.214,0.802,0.28,0.856,0.356,0.918,0.374,1,1,1')
	});
	gsap.to('.o-firstload', {
		autoAlpha: 0,
		duration: 0.9,
		delay: 1.6,
		ease: CustomEase.create('custom', 'M0,0,C0.084,0.61,0.214,0.802,0.28,0.856,0.356,0.918,0.374,1,1,1')
	});
	// Preloader
	gsap.to('.o-preloader', {
		scaleY: 0,
		transformOrigin: 'center top',
		duration: 1.3,
		delay: 1.6,
		ease: CustomEase.create('custom', 'M0,0,C0.084,0.61,0.214,0.802,0.28,0.856,0.356,0.918,0.374,1,1,1'),
		onComplete: removeSoluceLogo
	});
	// HTML & Body
	// gsap.set($('[data-load-container]'), {
	// 	overflow: 'hidden'
	// });
	// Clear props on el
	gsap.from('section .o-container', {
		y: 200,
		autoAlpha: 0,
		duration: 1.3,
		delay: 1.9,
		ease: CustomEase.create('custom', 'M0,0,C0.084,0.61,0.214,0.802,0.28,0.856,0.356,0.918,0.374,1,1,1'),
		// onComplete: fakeResize
	});
	if ($('.o-section_images').length) {
		gsap.to('.o-section_images .c-bg-img', {
			autoAlpha: 0,
			duration: 1.3,
			stagger: -0.6,
			delay: 1.9,
			ease: CustomEase.create('custom', 'M0,0,C0.084,0.61,0.214,0.802,0.28,0.856,0.356,0.918,0.374,1,1,1'),
			onComplete: removeSectionImages
		});
		gsap.fromTo('.o-section_career-introduction .c-bg-img, .o-section_career-introduction video', {
			scale: 1.2
		}, {
			scale: 1,
			delay: 5,
			duration: 5,
			ease: CustomEase.create('custom', 'M0,0,C0.084,0.61,0.214,0.802,0.28,0.856,0.356,0.918,0.374,1,1,1'),
		});
	}
}

// Leave transition
function transitionLeave() {
	// Preloader
	gsap.to('.o-preloader', {
		scaleY: 1,
		transformOrigin: 'center bottom',
		duration: 1.3,
		ease: CustomEase.create('custom', 'M0,0,C0.084,0.61,0.214,0.802,0.28,0.856,0.356,0.918,0.374,1,1,1')
	});
	// HTML & Body
	// gsap.set($('body, html, [data-load-container]'), {
	// 	overflow: 'hidden'
	// });
	// Global transition
	gsap.to('section .o-container', {
		y: -200,
		autoAlpha: 0,
		duration: 1.3,
		ease: CustomEase.create('custom', 'M0,0,C0.084,0.61,0.214,0.802,0.28,0.856,0.356,0.918,0.374,1,1,1'),
	});
	// HTML & Body
	gsap.set($('main'), {
		clearProps: 'all',
		delay: 1.3
	});
}

// Enter transition
function transitionEnter() {
	// Preloader
	gsap.to('.o-preloader', {
		scaleY: 0,
		transformOrigin: 'center top',
		duration: 1.3,
		delay: 1.3,
		ease: CustomEase.create('custom', 'M0,0,C0.084,0.61,0.214,0.802,0.28,0.856,0.356,0.918,0.374,1,1,1')
	});
	// HTML & Body
	// gsap.set($('body, html, [data-load-container]'), {
	// 	clearProps: 'all',
	// 	delay: 1.3
	// });
	// Global transition
	gsap.from('section .o-container', {
		y: 200,
		autoAlpha: 0,
		duration: 1.3,
		delay: 1.6,
		ease: CustomEase.create('custom', 'M0,0,C0.084,0.61,0.214,0.802,0.28,0.856,0.356,0.918,0.374,1,1,1'),
		// onComplete: fakeResize
	});
	if ($('.o-section_images').length) {
		gsap.to('.o-section_images .c-bg-img', {
			autoAlpha: 0,
			duration: 1.3,
			stagger: -0.6,
			delay: 1.9,
			ease: CustomEase.create('custom', 'M0,0,C0.084,0.61,0.214,0.802,0.28,0.856,0.356,0.918,0.374,1,1,1'),
			onComplete: removeSectionImages
		});
	}
}

// Export functions
module.exports = {
	transitionFirstLoad,
	transitionLeave,
	transitionEnter
};
