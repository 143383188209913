import $ from 'jquery';
import Swiper, {
	Navigation,
	Pagination,
	Scrollbar
} from 'swiper/core';

function popupInit() {
	let swiper;
	$('.-popup-link').each(function buttonPopup() {
		const content = $(this).find('.-bio').html();
		$(this).on('click', () => {
			$('.c-popup').addClass('is-active');
			$('.c-popup_content .o-layout').html(content);
		});

		$('.c-popup-close, .c-popup .c-bg-color').on('click', () => {
			$('.c-popup').removeClass('is-active');
		});
	});

	$('.-popup-button').each(function buttonPopup() {
		const content = $(this).data('content');
		$(this).on('click', () => {
			$('.c-gallery-popup').addClass('is-active');
			$('.c-gallery-popup_gallery .swiper .swiper-wrapper').html(content);
			swiper = new Swiper('.swiper', {
				grabCursor: true,
				// If we need pagination
				pagination: {
					el: '.swiper-pagination'
				},
				// Navigation arrows
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				scrollbar: {
					el: '.swiper-scrollbar',
					draggable: true,
				},
			});
		});

		$('.c-gallery-popup-close').on('click', () => {
			$('.c-gallery-popup').removeClass('is-active');
			setTimeout(() => {
				$('.c-gallery-popup_gallery .swiper .swiper-wrapper').empty();
				swiper.destroy();
			}, 250);
		});
	});

	$('.-popup-select').each(function selectPopup() {
		$(this).on('click', () => {
			$('.c-popup-select').addClass('is-active');
		});

		$('.c-popup-close').on('click', () => {
			$('.c-popup-select').removeClass('is-active');
		});

		$('.c-popup-select .c-bg-color').on('click', () => {
			$('.c-popup-select').removeClass('is-active');
		});
	});
}

module.exports = {
	popupInit
};
