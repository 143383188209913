// Packages
import $ from 'jquery';

// Init tabs
function tabsInit() {
	$('.o-tabs_tab').on('click', function test() {
		const id = $(this).index();
		$('.o-tabs_tab').removeClass('is-active');
		$(this).addClass('is-active');
		$('.o-tabs_content').each(function test2() {
			if ($(this).index() === id) {
				$(this).addClass('is-active');
			} else {
				$(this).removeClass('is-active');
			}
		});
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 1300);
	});
}

// Export functions
module.exports = {
	tabsInit
};
