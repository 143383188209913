// Packages
import $ from 'jquery';
import Swiper, {
	Navigation,
	Pagination,
	Scrollbar,
	Autoplay
} from 'swiper/core';

// Swiper config
Swiper.use([Navigation, Pagination, Scrollbar, Autoplay]);

// Init sliders
function slidersInit() {
	// Slider example
	// if ($('.swiper-container').length) {
	// 	// eslint-disable-next-line no-unused-vars
	// 	const swiper = new Swiper('.swiper-container', {
	// 		grabCursor: true,
	// 		// If we need pagination
	// 		pagination: {
	// 			el: '.swiper-pagination'
	// 		},
	// 		// Navigation arrows
	// 		navigation: {
	// 			nextEl: '.swiper-button-next',
	// 			prevEl: '.swiper-button-prev',
	// 		},
	// 		scrollbar: {
	// 			el: '.swiper-scrollbar',
	// 			draggable: true,
	// 		},
	// 	});
	// }
	// Qualité des installations
	if ($('.swiper-container-installations').length) {
		// eslint-disable-next-line no-unused-vars
		const swiper = new Swiper('.swiper-container-installations', {
			grabCursor: true,
			// If we need pagination
			pagination: {
				el: '.swiper-pagination'
			},
			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			autoplay: {
				delay: 2500,
			},
			loop: true
		});
	}
}

// Export functions
module.exports = {
	slidersInit
};
