// Packages
import $ from 'jquery';
import Accordion from 'accordion-js';

// Init sliders
function accordionsInit() {
	// Accordion JS
	const accordions = Array.from(document.querySelectorAll('.o-accordion-container'));

	if (accordions) {
		let count = 0;
		let id = null;

		accordions.forEach((item) => {
			id = `accordion${count}`;
			item.id = id;
			// eslint-disable-next-line no-unused-vars
			const accordion = new Accordion(`#${id}`, {
				duration: 900,
				showItem: false,
				elementClass: 'o-accordion',
				triggerClass: 'o-accordion-question__wrap',
				panelClass: 'o-accordion-answer'
			});
			count += 1;
			window.dispatchEvent(new Event('resize'));
		});

		$('.o-accordion-question__wrap').on('click', () => {
			setTimeout(() => {
				window.dispatchEvent(new Event('resize'));
			}, 901);
		});
	}
}

// Export functions
module.exports = {
	accordionsInit
};
