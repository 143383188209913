// Packages
import $ from 'jquery';
import List from 'list.js';

// Init sliders
function listInit() {
	const options = {
		valueNames: ['name', 'title', 'email', 'city', 'job', 'extension']
	};

	const employeesList = new List('employees_list', options);
	let activeFilters = [];
	function filter() {
		activeFilters = [];
		$('.select').each(function test() {
			const selection = $(this).val();
			activeFilters.push(selection);
		});

		if ($.inArray('trois-rivieres', activeFilters) !== -1) {
			$('.-trois-rivieres').show();
			$('.-becancour').hide();
		} else if ($.inArray('becancour', activeFilters) !== -1) {
			$('.-trois-rivieres').hide();
			$('.-becancour').show();
		}

		employeesList.on('updated', (list) => {
			if (list.matchingItems.length > 0) {
				$('.-no-result').hide();
				if (list.matchingItems.length === 1) {
					$('.-number').html(`${list.matchingItems.length} résultat`);
				} else {
					$('.-number').html(`${list.matchingItems.length} résultats`);
				}
			} else {
				$('.-no-result').show();
				$('.-number').html(`${list.matchingItems.length} résultat`);
			}
		});

		if ($.inArray('all', activeFilters) !== -1) {
			employeesList.filter((item) => {
				const city = $.trim(item.values().city);
				if (item.values().city.indexOf('|') > 0) {
					const cities = $.trim(item.values().city.split('|'));
					if ($.inArray((cities[0], activeFilters) !== -1
							|| $.inArray(cities[1], activeFilters) !== -1)) {
						return true;
					}
				} else if ($.inArray(city, activeFilters) !== -1) {
					return true;
				}
				return false;
			});
		} else {
			employeesList.filter((item) => {
				const city = $.trim(item.values().city);
				const job = $.trim(item.values().job);
				if (item.values().city.indexOf('|') > 0) {
					const cities = $.trim(item.values().city.split('|'));
					if ($.inArray((cities[0], activeFilters) !== -1
							|| $.inArray(cities[1], activeFilters) !== -1)
						&& $.inArray(job, activeFilters) !== -1) {
						return true;
					}
				} else if ($.inArray(city, activeFilters) !== -1
					&& $.inArray(job, activeFilters) !== -1) {
					return true;
				}
				return false;
			});
		}

		window.dispatchEvent(new Event('resize'));
	}

	if ($('#employees_list').length) {
		filter();

		$('.select').on('change', () => {
			filter();
		});
	}
}

// Export functions
module.exports = {
	listInit
};
