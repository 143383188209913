// Packages
import $ from 'jquery';

// Refresh Wordpress Plugins
function woocommerceInit() {
	// Generate input number styling
	function generateInputNumber() {
		$('<div class="quantity-nav"><div class="quantity-button quantity-up">+</div><div class="quantity-button quantity-down">-</div></div>').insertAfter('.quantity input');
		$('.quantity').each(function setupNumbersStyling() {
			const spinner = $(this);
			const input = spinner.find('input[type="number"]');
			const btnUp = spinner.find('.quantity-up');
			const btnDown = spinner.find('.quantity-down');
			const min = input.attr('min');
			const max = input.attr('max');

			btnUp.on('click', () => {
				const oldValue = parseFloat(input.val());
				let newVal;
				if (oldValue >= max && !max === null) {
					newVal = oldValue;
				} else {
					newVal = oldValue + 1;
					$('.button-update-cart button').prop('disabled', false);
				}
				spinner.find('input').val(newVal);
				spinner.find('input').trigger('change');
			});

			btnDown.on('click', () => {
				const oldValue = parseFloat(input.val());
				let newVal;
				if (oldValue <= min) {
					newVal = oldValue;
				} else {
					newVal = oldValue - 1;
					$('.button-update-cart button').prop('disabled', false);
				}
				spinner.find('input').val(newVal);
				spinner.find('input').trigger('change');
			});
		});
	}
	generateInputNumber();
	// Woocommerce Cart
	if ($('.woocommerce').length) {
		jQuery(document.body).on('wc_cart_emptied update_checkout updated_wc_div updated_cart_totals country_to_state_changed updated_shipping_method applied_coupon removed_coupon init_checkout payment_method_selected updated_checkout checkout_error applied_coupon_in_checkout removed_coupon_in_checkout', () => {
			if (!$('.quantity-nav').length) {
				generateInputNumber();
			}
		});
	}
}

// Export functions
module.exports = {
	woocommerceInit
};
