// Packages
import $ from 'jquery';
import Scrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/dist/plugins/overscroll';
import gsap from 'gsap';
import {
	ScrollTrigger
} from 'gsap/ScrollTrigger';
import {
	SplitText
} from 'gsap/SplitText';
import {
	CSSPlugin
} from 'gsap/CSSPlugin';
import {
	ScrambleTextPlugin
} from 'gsap/ScrambleTextPlugin';
import {
	CSSRulePlugin
} from 'gsap/CSSRulePlugin';
import SoftScroll from './_softscroll';

// ---- Register GSAP plugins ----
gsap.registerPlugin(ScrollTrigger, SplitText, CSSPlugin, ScrambleTextPlugin, CSSRulePlugin);

let gsapScrollTrigger;
let bodyScrollBar;

function initScroll(isMobile) {
	if (isMobile) { // Is Mobile
		// Delete intro videos
		$('.o-section_intro .-video1 video').remove();
		$('.o-section_intro .-video2 video').remove();
		// Kill all scrolltriggers
		const Alltrigger = ScrollTrigger.getAll();
		for (let i = 0; i < Alltrigger.length; i += 1) {
			Alltrigger[i].kill(true);
		}
		gsapScrollTrigger = $('body');
	} else { // Is Desktop
		// Delete intro images
		$('.o-section_intro .-video1 .c-bg-img').remove();
		$('.o-section_intro .-video2 .c-bg-img').remove();
		// Kill all scrolltriggers
		const Alltrigger = ScrollTrigger.getAll();
		for (let i = 0; i < Alltrigger.length; i += 1) {
			Alltrigger[i].kill(true);
		}
		gsapScrollTrigger = $('#scroll-container');
		$('html').addClass('has-smooth-scrollbar');

		Scrollbar.use(OverscrollPlugin, SoftScroll);

		bodyScrollBar = Scrollbar.init(document.querySelector('#scroll-container'), {
			plugins: {
				overscroll: {
					effect: 'glow',
					glowColor: 'transparent'
				}
			}
		});

		bodyScrollBar.addListener(() => {
			if ($('.select2-container--open').length) {
				const position = $('.select2-container--open').first().offset().top - $(window).scrollTop();
				$('.select2-container--open').last().css({
					top: position + 42
				});
			}
		});

		bodyScrollBar.setPosition(0, 0);
		bodyScrollBar.track.xAxis.element.remove();

		ScrollTrigger.scrollerProxy(gsapScrollTrigger, {
			scrollTop(value) {
				if (arguments.length) {
					bodyScrollBar.scrollTop = value;
				}
				return bodyScrollBar.scrollTop;
			}
		});

		bodyScrollBar.addListener(ScrollTrigger.update);
	}

	// --------------------------------
	// ---------- Animations ----------
	// --------------------------------

	// Images parallax
	// ---------------
	$('.-parallax').each(function parallax() {
		gsap.fromTo($(this).find('.c-bg-img'), {
			yPercent: -25,
			ease: 'none'
		}, {
			scrollTrigger: {
				trigger: $(this),
				scroller: gsapScrollTrigger,
				invalidateOnRefresh: true,
				scrub: true,
				// markers: true,
				// start: '-40px bottom',
				// end: '100%+=40px top'
			},
			yPercent: 25,
			ease: 'none'
		});
	});

	// Text change on hover
	// --------------------
	function resetTextOnResize() {
		$('.o-section_intro #introTitle .line:first-child .word__wrap:first-child .word').html('La&nbsp;');
		$('.o-section_intro #introTitle .line:first-child .word__wrap:last-child .word').text('force');
		$('.o-section_intro #introTitle .line:last-child .word__wrap:first-child .word').text('d\'une');
		$('.o-section_intro #introTitle .line:last-child .word__wrap:last-child .word').text('synergie');
	}

	$.fn.cleanWhitespace = function cleanWhitespaceFromElement() {
		this.contents().filter(
			function removeSpaces() {
				return (this.nodeType === 3 && !/\S/.test(this.nodeValue));
			}
		)
			.remove();
		return this;
	};

	function removeSpacesFromHtml() {
		$('.o-section_intro #introTitle .line:first-child').cleanWhitespace();
	}

	function changeIntroText() {
		$('.o-section_intro #introTitle .line .word__wrap:last-child').hover(() => {
			gsap.to('.o-section_intro #introTitle .line:first-child .word__wrap:first-child .word', {
				duration: 1.3,
				scrambleText: {
					text: 'L\'',
					chars: 'lowerCase',
					speed: 0.9,
					onStart: removeSpacesFromHtml
				}
			});
			gsap.to('.o-section_intro #introTitle .line:first-child .word__wrap:last-child .word', {
				duration: 1.3,
				scrambleText: {
					text: 'expertise',
					chars: 'lowerCase',
					speed: 0.9
				}
			});
			gsap.to('.o-section_intro #introTitle .line:last-child .word__wrap:first-child .word', {
				duration: 1.3,
				scrambleText: {
					text: 'd\'une',
					chars: 'lowerCase',
					speed: 0.9
				}
			});
			gsap.to('.o-section_intro #introTitle .line:last-child .word__wrap:last-child .word', {
				duration: 1.3,
				scrambleText: {
					text: 'équipe',
					chars: 'lowerCase',
					speed: 0.9
				}
			});

			if (isMobile) {
				gsap.fromTo('.o-section_intro .-video1, .o-section_intro .-video1-bg', {
					top: '49%',
					left: '46%'
				}, {
					top: '49%',
					left: '54%',
					duration: 1.3,
					ease: 'power4.inOut'
				});
				gsap.fromTo('.o-section_intro .-video2, .o-section_intro .-video2-bg', {
					top: '50%',
					left: '6%'
				}, {
					top: '50%',
					left: '14%',
					duration: 1.3,
					ease: 'power4.inOut'
				});
			} else {
				gsap.fromTo('.o-section_intro .-video1, .o-section_intro .-video1-bg', {
					top: '49%',
					left: '46%'
				}, {
					top: '49%',
					left: '54%',
					duration: 1.3,
					ease: 'power4.inOut'
				});
				gsap.fromTo('.o-section_intro .-video2, .o-section_intro .-video2-bg', {
					top: '51%',
					left: '12%'
				}, {
					top: '51%',
					left: '19%',
					duration: 1.3,
					ease: 'power4.inOut'
				});
			}
			$('.o-section_intro #introTitle .line .word__wrap:last-child').unbind('mouseenter mouseleave');
		});
	}

	// Introduction timeline
	// --------------------
	const introTl = gsap.timeline();
	const introSplitText = new SplitText('.o-section_intro #introTitle', {
		type: 'lines, words',
		linesClass: 'line',
		wordsClass: 'word'
	});
	const introWords = introSplitText.words;

	$('.o-section_intro #introTitle .word').wrap('<div class="word__wrap">');
	$('.o-section_intro #introTitle .line .word__wrap:last-child').prepend('<div class="word__underline"/>');

	gsap.set('.o-section_intro #introTitle .line:first-child', {
		xPercent: -50,
		yPercent: -75,
		x: 0,
		y: 0,
		left: '50%'
	});

	gsap.set('.o-section_intro #introTitle .line:last-child', {
		xPercent: -50,
		yPercent: 15,
		x: 0,
		y: 0,
		left: '50%'
	});

	introTl.fromTo(introWords, {
		yPercent: 120,
		rotate: 10
	}, {
		yPercent: 0,
		rotate: 0,
		duration: 1.3,
		stagger: 0.1,
		delay: 1.3,
		ease: 'power4.out'
	});

	introTl.fromTo('.o-section_intro #introTitle .line:first-child', {
		xPercent: -50,
		yPercent: -75,
		x: 0,
		y: 0,
		left: '50%'
	}, {
		xPercent: 0,
		yPercent: -75,
		left: '8.333333%',
		duration: 1.3,
		ease: 'power4.inOut'
	}, '-=0.6');

	introTl.fromTo('.o-section_intro #introTitle .line:last-child', {
		xPercent: -50,
		yPercent: 15,
		x: 0,
		y: 0,
		left: '50%'
	}, {
		xPercent: -100,
		yPercent: 15,
		left: '91.666667%',
		duration: 1.3,
		ease: 'power4.inOut'
	}, '-=1.3');

	introTl.fromTo('.o-section_intro .-video1, .o-section_intro .-video2', {
		scaleY: 0
	}, {
		scaleY: 1,
		duration: 1.9,
		ease: 'power4.out'
	}, '-=1.3');

	introTl.fromTo('.o-section_intro .-video1-bg, .o-section_intro .-video2-bg', {
		scaleY: 0
	}, {
		scaleY: 1,
		duration: 1.9,
		ease: 'power4.out'
	}, '-=1.3');

	introTl.set('.o-section_intro .-video1, .o-section_intro .-video2, .o-section_intro .-video1-bg, .o-section_intro .-video2-bg', {
		clearProps: 'all'
	});

	introTl.fromTo('.o-section_intro .word__underline', {
		scaleX: 0,
		transformOrigin: 'center left'
	}, {
		scaleX: 1,
		duration: 1.9,
		ease: 'power4.out'
	}, '-=1.3');

	introTl.add(changeIntroText, '-=1.7');

	introTl.fromTo('.o-section_intro .-scroll-down', {
		autoAlpha: 0,
		yPercent: -100,
		transformOrigin: 'center left'
	}, {
		autoAlpha: 1,
		yPercent: 0,
		duration: 1.3,
		stagger: -0.15,
		ease: 'power4.out'
	}, '-=1.9');

	// Restart introTl on window resize (the only way to make TL kinda responsive)
	// ---------------------------------------------------------------------------
	let width = $(window).width();
	$(window).on('resize', function resizeX() {
		if ($(this).width() !== width) {
			width = $(this).width();
			gsap.to('#introTitle .line:first-child', {
				clearProps: 'all'
			});
			gsap.set('.-video1, .-video2, .-video1-bg, .-video2-bg', {
				clearProps: 'all'
			});
			gsap.set('.-video1, .-video2, .-video1-bg, .-video2-bg', {
				scaleY: 0
			});
			resetTextOnResize();
			introTl.pause(0).invalidate().play(1);
		}
	});

	// Intro text lines parallax
	// -------------------------
	gsap.fromTo('#introTitle .line:first-child .word__wrap', {
		x: 0,
		ease: 'none'
	}, {
		scrollTrigger: {
			trigger: '.o-section_intro',
			scroller: gsapScrollTrigger,
			invalidateOnRefresh: true,
			scrub: true,
			// markers: true,
			start: 'top top',
			end: 'bottom top'
		},
		x: window.innerWidth / 2,
		ease: 'none'
	});

	gsap.fromTo('#introTitle .line:last-child .word__wrap', {
		x: 0,
		ease: 'none'
	}, {
		scrollTrigger: {
			trigger: '.o-section_intro',
			scroller: gsapScrollTrigger,
			invalidateOnRefresh: true,
			scrub: true,
			// markers: true,
			start: 'top top',
			end: 'bottom top'
		},
		x: -window.innerWidth / 2,
		ease: 'none'
	});

	// Gallery reveal
	// --------------
	if (!isMobile) {
		gsap.fromTo('.-gallery__wrap', {
			transformOrigin: 'center top',
			yPercent: -20,
			scale: 3,
			ease: 'none'
		}, {
			scrollTrigger: {
				trigger: '.o-section_gallery',
				scroller: gsapScrollTrigger,
				invalidateOnRefresh: true,
				scrub: true,
				pin: '.o-section_gallery',
				// markers: true,
				start: 'top top',
				end: '100% top'
			},
			transformOrigin: 'center top',
			yPercent: 0,
			scale: 1,
			ease: 'none'
		});
	} else {
		gsap.fromTo('.-gallery__wrap', {
			transformOrigin: 'center top',
			yPercent: -20,
			scale: 3,
			ease: 'none'
		}, {
			scrollTrigger: {
				trigger: '.o-section_gallery',
				scroller: gsapScrollTrigger,
				invalidateOnRefresh: true,
				scrub: true,
				// markers: true,
				start: 'top 60%',
				end: '100% 60%'
			},
			transformOrigin: 'center top',
			yPercent: 0,
			scale: 1,
			ease: 'none'
		});
	}

	function checkSiteColor(elem) {
		if (elem.getAttribute('data-color') === '#1c1c1c' || elem.getAttribute('data-color') === '#4184bf') {
			$('main').removeClass('-light');
			$('main').addClass('-dark');
		} else if (elem.getAttribute('data-color') === '#ededed') {
			$('main').removeClass('-dark');
			$('main').addClass('-light');
		}
	}

	// Sections color change
	// ---------------------
	const targets = gsap.utils.toArray('.-color-change');
	targets.forEach((elem) => {
		const color = elem.getAttribute('data-color');
		ScrollTrigger.create({
			trigger: elem,
			scroller: gsapScrollTrigger,
			invalidateOnRefresh: true,
			start: 'top 50%',
			end: 'bottom 50%',
			onEnter: () => gsap.to('main', {
				backgroundColor: color,
				duration: 0.4,
				ease: 'none',
				onComplete: checkSiteColor(elem)
			}),
			onEnterBack: () => gsap.to('main', {
				backgroundColor: color,
				duration: 0.4,
				ease: 'none',
				onComplete: checkSiteColor(elem)
			}),
			// markers: true
		});
	});

	// About reveal
	// ------------
	const aboutTl = gsap.timeline({
		scrollTrigger: {
			trigger: '.o-section_about',
			scroller: gsapScrollTrigger,
			// markers: true,
			start: '25% bottom',
			end: '25% bottom'
		}
	});

	gsap.set('.o-section_about .c-bg-img_gradient, .o-section_about .o-ratio .c-bg-img', {
		scaleY: 0
	});

	aboutTl.fromTo('.o-section_about .c-bg-img_gradient, .o-section_about .o-ratio .c-bg-img', {
		scaleY: 0
	}, {
		scaleY: 1,
		duration: 1.9,
		stagger: -0.3,
		ease: 'power4.out'
	});

	// About values parallax
	// ---------------------
	const valuesSplitText = new SplitText('.-values', {
		type: 'words',
		wordsClass: 'word'
	});
	const valuesWords = valuesSplitText.words;

	gsap.fromTo(valuesWords[0], {
		y: 200,
		ease: 'none'
	}, {
		scrollTrigger: {
			trigger: '.o-section_about',
			scroller: gsapScrollTrigger,
			invalidateOnRefresh: true,
			scrub: true,
			// markers: true,
			// start: 'top bottom',
			// end: 'bottom bottom'
		},
		y: 0,
		ease: 'none'
	});

	gsap.fromTo(valuesWords[1], {
		y: 225,
		ease: 'none'
	}, {
		scrollTrigger: {
			trigger: '.o-section_about',
			scroller: gsapScrollTrigger,
			invalidateOnRefresh: true,
			scrub: true,
			// markers: true,
			// start: 'top bottom',
			// end: 'bottom bottom'
		},
		y: -25,
		ease: 'none'
	});

	gsap.fromTo(valuesWords[2], {
		y: 250,
		ease: 'none'
	}, {
		scrollTrigger: {
			trigger: '.o-section_about',
			scroller: gsapScrollTrigger,
			invalidateOnRefresh: true,
			scrub: true,
			// markers: true,
			// start: 'top bottom',
			// end: 'bottom bottom'
		},
		y: -50,
		ease: 'none'
	});

	// Services reveal
	// ---------------
	const servicesTl = gsap.timeline({
		scrollTrigger: {
			trigger: '.o-section_services',
			scroller: gsapScrollTrigger,
			// markers: true,
			start: '25% bottom',
			end: '25% bottom'
		}
	});

	gsap.set('.o-section_services .c-bg-img_gradient, .o-section_services .o-ratio .c-bg-img', {
		scaleY: 0
	});

	servicesTl.fromTo('.o-section_services .c-bg-img_gradient, .o-section_services .o-ratio .c-bg-img', {
		scaleY: 0
	}, {
		scaleY: 1,
		duration: 1.9,
		stagger: -0.3,
		ease: 'power4.out'
	});

	// Career reveal
	// ------------
	const careerTl = gsap.timeline({
		scrollTrigger: {
			trigger: '.o-section_career',
			scroller: gsapScrollTrigger,
			// markers: true,
			start: '25% bottom',
			end: '25% bottom'
		}
	});

	gsap.set('.o-section_career .c-bg-img_gradient, .o-section_career .o-ratio .c-bg-img', {
		scaleY: 0
	});

	careerTl.fromTo('.o-section_career .-left .c-bg-img_gradient, .o-section_career .-left .o-ratio .c-bg-img', {
		scaleY: 0
	}, {
		scaleY: 1,
		duration: 1.9,
		stagger: -0.3,
		ease: 'power4.out'
	});

	careerTl.fromTo('.o-section_career .-right .c-bg-img_gradient, .o-section_career .-right .o-ratio .c-bg-img', {
		scaleY: 0
	}, {
		scaleY: 1,
		duration: 1.9,
		stagger: -0.3,
		ease: 'power4.out'
	}, '>-1.9');

	// Header timeline
	// ---------------
	const headerTl = gsap.timeline();
	const headerSplitText = new SplitText('.o-section_header .o-text h1, .o-section_header .o-text h2, .o-section_header .o-text h3, .o-section_header .o-text h4, .o-section_header .o-text h5, .o-section_header .o-text h6, .o-section_header .o-text p', {
		type: 'words',
		wordsClass: 'word'
	});
	const headerWords = headerSplitText.words;

	$('.o-section_header .o-text .word').wrap('<div class="word__wrap">');

	gsap.set('.o-section_header .c-bg-img_gradient, .o-section_header .o-ratio .c-bg-img', {
		scaleY: 0
	});

	headerTl.fromTo(headerWords, {
		yPercent: 150,
		rotate: 10
	}, {
		yPercent: 0,
		rotate: 0,
		duration: 1.3,
		stagger: 0.1,
		delay: 1.3,
		ease: 'power4.out'
	});

	headerTl.fromTo('.o-section_header .c-bg-img_gradient, .o-section_header .o-ratio .c-bg-img', {
		scaleY: 0
	}, {
		scaleY: 1,
		duration: 1.9,
		stagger: -0.3,
		ease: 'power4.out'
	}, '-=0.9');

	// Video section timeline
	// ----------------------
	$('.o-section_video').each(function revealVideoSections() {
		const videoSectionTl = gsap.timeline({
			scrollTrigger: {
				trigger: $(this).find('.c-button_play'),
				scroller: gsapScrollTrigger,
				// markers: true,
				start: '25% bottom',
				end: '25% bottom'
			}
		});

		videoSectionTl.fromTo($(this).find('.c-button_play'), {
			autoAlpha: 0,
			scale: 0.5
		}, {
			autoAlpha: 1,
			scale: 1,
			duration: 1.3,
			ease: 'power4.out'
		});
	});

	// Career Track section reveal
	// ---------------------------
	const trackTL = gsap.timeline({
		scrollTrigger: {
			trigger: '.o-section_carrieres-track',
			scroller: gsapScrollTrigger,
			// markers: true,
			start: '35% bottom',
			end: '35% bottom'
		}
	});

	gsap.set('.o-section_carrieres-track .c-img__wrap .o-ratio:nth-child(2), .o-section_carrieres-track .c-img__wrap .o-ratio:nth-child(1)', {
		x: 0,
		y: 0,
		scale: 1,
		rotate: 0
	});

	gsap.set('.o-section_carrieres-track .toggle', {
		autoAlpha: 0,
		scale: 0.8
	});

	trackTL.fromTo('.o-section_carrieres-track .c-img__wrap .o-ratio:nth-child(2)', {
		xPercent: 0,
		yPercent: 0,
		scale: 1,
		rotate: 0
	}, {
		xPercent: 22,
		yPercent: 13,
		scale: 0.9,
		rotate: 15,
		duration: 1.9,
		delay: 0.6,
		ease: 'power4.out'
	});

	trackTL.fromTo('.o-section_carrieres-track .c-img__wrap .o-ratio:nth-child(1)', {
		xPercent: 0,
		yPercent: 0,
		scale: 1,
		rotate: 0
	}, {
		xPercent: 68,
		yPercent: 5,
		scale: 0.8,
		rotate: -15,
		duration: 1.9,
		ease: 'power4.out'
	}, '-=1.9');

	trackTL.to('.o-section_carrieres-track .toggle', {
		autoAlpha: 1,
		scale: 1,
		duration: 1.9,
		ease: 'power4.out'
	}, '-=0.95');

	// Career Advantages section reveal
	// --------------------------------
	const advantagesTL = gsap.timeline({
		scrollTrigger: {
			trigger: '.o-section-carrieres_trois-blocks',
			scroller: gsapScrollTrigger,
			// markers: true,
			start: '35% bottom',
			end: '35% bottom'
		}
	});

	gsap.set('.o-section-carrieres_trois-blocks .o-layout_item h2', {
		autoAlpha: 0,
		scale: 0
	});

	gsap.set('.o-section-carrieres_trois-blocks .o-layout_item p', {
		autoAlpha: 0,
		scale: 0
	});

	advantagesTL.add('start');

	advantagesTL.fromTo('.o-section-carrieres_trois-blocks .o-layout_item', {
		autoAlpha: 0,
		scale: 0
	}, {
		autoAlpha: 1,
		scale: 1,
		stagger: 0.25,
		duration: 1.9,
		ease: 'power4.out'
	});

	advantagesTL.fromTo('.o-section-carrieres_trois-blocks .o-layout_item p', {
		autoAlpha: 0,
		scale: 0
	}, {
		autoAlpha: 1,
		scale: 1,
		stagger: 0.125,
		duration: 1.9,
		ease: 'power4.out'
	}, 'start');

	advantagesTL.fromTo('.o-section-carrieres_trois-blocks .o-layout_item h2', {
		autoAlpha: 0,
		scale: 0
	}, {
		autoAlpha: 1,
		scale: 1,
		stagger: 0.25,
		duration: 1.9,
		ease: 'power4.out'
	}, 'start');

	// Distinction section reveal
	// ------------
	const distinctionTL = gsap.timeline({
		scrollTrigger: {
			trigger: '.o-section-carrieres_distinctions',
			scroller: gsapScrollTrigger,
			// markers: true,
			start: '20% bottom',
			end: '20% bottom'
		}
	});

	gsap.set('.o-section-carrieres_distinctions .c-bg-img', {
		scaleY: 0
	});

	distinctionTL.add('start');

	distinctionTL.fromTo('.o-section-carrieres_distinctions .ligne', {
		width: 0,

	}, {
		width: '100%',
		duration: 1,
		ease: 'power4.out',
		stagger: 0.5,
	}, 'start');

	distinctionTL.from('.o-section-carrieres_distinctions .--wrap_text', {
		yPercent: 50,
		autoAlpha: 0,
		duration: 0.5,
		ease: 'power4.out',
		stagger: 1,
	}, 'start');

	distinctionTL.fromTo('.o-section-carrieres_distinctions .c-bg-img', {
		scaleY: 0
	}, {
		scaleY: 1,
		duration: 1.9,
		stagger: -0.3,
		ease: 'power4.out'
	}, 'start');

	if ($(window).width() < 1000) {
		// mobile
		// Images à la Gsoft
		// -----------------
		let imagesGsTL = gsap.timeline({
			paused: true
		});

		let img1 = $('.o-section_carrieres-track .c-img__wrap .o-ratio:nth-child(1)');
		let img2 = $('.o-section_carrieres-track .c-img__wrap .o-ratio:nth-child(2)');
		let img3 = $('.o-section_carrieres-track .c-img__wrap .o-ratio:nth-child(3)');

		imagesGsTL.add('start');

		imagesGsTL.to('.o-section_carrieres-track .toggle', {
			xPercent: 0,
			yPercent: 0,
			top: -5,
			left: -5,
			duration: 1,
			ease: 'power4.inOut'
		}, 'start');

		imagesGsTL.to('.o-section_carrieres-track .toggle svg', {
			rotate: 180,
			duration: 1,
			ease: 'power4.inOut'
		}, 'start');

		imagesGsTL.to(img1, {
			xPercent: 175,
			yPercent: 0,
			rotate: 0,
			scale: 1,
			duration: 1,
			ease: 'power4.inOut'
		}, 'start');

		imagesGsTL.to(img2, {
			xPercent: 88,
			yPercent: 0,
			rotate: 0,
			scale: 1,
			duration: 1,
			ease: 'power4.inOut'
		}, 'start');

		imagesGsTL.to(img3, {
			xPercent: 0,
			yPercent: 0,
			rotate: 0,
			scale: 1,
			duration: 1,
			ease: 'power4.inOut'
		}, 'start');

		$('.toggle').on('click', () => {
			$('.o-section_carrieres-track .c-img__wrap').toggleClass('active');
			if ($('.o-section_carrieres-track .c-img__wrap').hasClass('active')) {
				imagesGsTL.play();
			} else {
				imagesGsTL.reverse();
			}
		});

		function testttffdsfsd() {
			imagesGsTL = gsap.timeline({
				paused: true
			});

			img1 = $('.o-section_carrieres-track .c-img__wrap .o-ratio:nth-child(1)');
			img2 = $('.o-section_carrieres-track .c-img__wrap .o-ratio:nth-child(2)');
			img3 = $('.o-section_carrieres-track .c-img__wrap .o-ratio:nth-child(3)');

			imagesGsTL.add('start');

		imagesGsTL.to('.o-section_carrieres-track .toggle', {
			xPercent: 0,
			yPercent: 0,
			top: -5,
			left: -5,
			duration: 1,
			ease: 'power4.inOut'
		}, 'start');

		imagesGsTL.to('.o-section_carrieres-track .toggle svg', {
			rotate: 180,
			duration: 1,
			ease: 'power4.inOut'
		}, 'start');

		imagesGsTL.to(img1, {
			xPercent: 233,
			yPercent: 0,
			rotate: 0,
			scale: 1,
			duration: 1,
			ease: 'power4.inOut'
		}, 'start');

		imagesGsTL.to(img2, {
			xPercent: 116,
			yPercent: 0,
			rotate: 0,
			scale: 1,
			duration: 1,
			ease: 'power4.inOut'
		}, 'start');

		imagesGsTL.to(img3, {
			xPercent: 0,
			yPercent: 0,
			rotate: 0,
			scale: 1,
			duration: 1,
			ease: 'power4.inOut'
		}, 'start');
		}

		$(window).on('resize', () => {
			$('.o-section_carrieres-track .c-img__wrap').removeClass('active');
			// imagesGsTL.reverse();
			gsap.set('.o-section_carrieres-track .o-text', {
				clearProps: 'all',
				// delay: 1.1
			});
			gsap.set('.o-section_carrieres-track .toggle', {
				clearProps: 'all',
				// delay: 1.1
			});
			gsap.set('.o-section_carrieres-track .toggle svg', {
				clearProps: 'all',
				// delay: 1.1
			});
			imagesGsTL.kill();
			testttffdsfsd();
			gsap.set('.o-section_carrieres-track .toggle', {
				autoAlpha: 0
			});
		});
	} else {
		// desktop
		// Images à la Gsoft
		// -----------------
		let imagesGsTL = gsap.timeline({
			paused: true
		});

		let img1 = $('.o-section_carrieres-track .c-img__wrap .o-ratio:nth-child(1)');
		let img2 = $('.o-section_carrieres-track .c-img__wrap .o-ratio:nth-child(2)');

		imagesGsTL.add('start');

		imagesGsTL.to('.o-section_carrieres-track .o-text', {
			autoAlpha: 0,
			xPercent: 50,
			duration: 1,
			ease: 'power4.inOut'
		});

		imagesGsTL.to('.o-section_carrieres-track .toggle', {
			xPercent: 0,
			yPercent: 0,
			top: -5,
			left: -5,
			duration: 1,
			ease: 'power4.inOut'
		}, 'start');

		imagesGsTL.to('.o-section_carrieres-track .toggle svg', {
			rotate: 180,
			duration: 1,
			ease: 'power4.inOut'
		}, 'start');

		imagesGsTL.to(img1, {
			xPercent: 300,
			yPercent: 0,
			rotate: 0,
			scale: 1,
			duration: 1,
			ease: 'power4.inOut'
		}, 'start');

		imagesGsTL.to(img2, {
			xPercent: 150,
			yPercent: 0,
			rotate: 0,
			scale: 1,
			duration: 1,
			ease: 'power4.inOut'
		}, 'start');

		$('.toggle').on('click', () => {
			$('.o-section_carrieres-track .c-img__wrap').toggleClass('active');
			if ($('.o-section_carrieres-track .c-img__wrap').hasClass('active')) {
				imagesGsTL.play();
			} else {
				imagesGsTL.reverse();
			}
		});

		function testttffdsfsd() {
			imagesGsTL = gsap.timeline({
				paused: true
			});

			img1 = $('.o-section_carrieres-track .c-img__wrap .o-ratio:nth-child(1)');
			img2 = $('.o-section_carrieres-track .c-img__wrap .o-ratio:nth-child(2)');

			imagesGsTL.add('start');

			imagesGsTL.to('.o-section_carrieres-track .o-text', {
				autoAlpha: 0,
				xPercent: 50,
				duration: 1,
				ease: 'power4.inOut'
			});

			imagesGsTL.to('.o-section_carrieres-track .toggle', {
				xPercent: 0,
				yPercent: 0,
				top: -5,
				left: -5,
				duration: 1,
				ease: 'power4.inOut'
			}, 'start');

			imagesGsTL.to('.o-section_carrieres-track .toggle svg', {
				rotate: 180,
				duration: 1,
				ease: 'power4.inOut'
			}, 'start');

			imagesGsTL.to(img1, {
				xPercent: 300,
				yPercent: 0,
				rotate: 0,
				scale: 1,
				duration: 1,
				ease: 'power4.inOut'
			}, 'start');

			imagesGsTL.to(img2, {
				xPercent: 150,
				yPercent: 0,
				rotate: 0,
				scale: 1,
				duration: 1,
				ease: 'power4.inOut'
			}, 'start');
		}

		$(window).on('resize', () => {
			$('.o-section_carrieres-track .c-img__wrap').removeClass('active');
			// imagesGsTL.reverse();
			gsap.set('.o-section_carrieres-track .o-text', {
				clearProps: 'all',
				// delay: 1.1
			});
			gsap.set('.o-section_carrieres-track .toggle', {
				clearProps: 'all',
				// delay: 1.1
			});
			gsap.set('.o-section_carrieres-track .toggle svg', {
				clearProps: 'all',
				// delay: 1.1
			});
			imagesGsTL.kill();
			testttffdsfsd();
			gsap.set('.o-section_carrieres-track .toggle', {
				autoAlpha: 0
			});
		});
	}

	// Markers position fix
	if (!isMobile) {
		// Only necessary to correct marker position - not needed in production
		if (document.querySelector('.gsap-marker-scroller-start')) {
			const markers = gsap.utils.toArray('[class *= "gsap-marker"]');
			bodyScrollBar.addListener(({
				offset
			}) => {
				gsap.set(markers, {
					marginTop: -offset.y
				});
			});
		}
	}
}

function scrollToTop() {
	bodyScrollBar.scrollIntoView(document.querySelector('section:first-child'), {
		offsetTop: 80
	});
}

function anchorLinks(isMobile) {
	if (isMobile) {
		$('.o-message button').on('click', () => {
			$('html, body').animate({
				scrollTop: 0
			}, 800);
		});
		$(document).on('click', 'a[href*="#"]', function test(e) {
			$('.o-message').removeClass('is-active');
			e.preventDefault();
			const href = $(this).attr('href');
			const hash = href.substr(href.indexOf('#'));
			$('html, body').animate({
				scrollTop: $(hash).offset().top - 80
			}, 800);
		});

		if (window.location.href.indexOf('#') > -1) {
			const {
				href
			} = window.location;
			const hash = href.substr(href.indexOf('#'));
			setTimeout(() => {
				$('html, body').animate({
					scrollTop: $(hash).offset().top - 80
				}, 800);
			}, 600);
		}

		$('.-scroll-down').on('click', () => {
			$('html, body').animate({
				scrollTop: $('section').next().offset().top - 80
			}, 800);
		});
	} else {
		$('.o-message button').on('click', () => {
			scrollToTop();
		});
		$(document).on('click', 'a[href*="#"]', (e) => {
			$('.o-message').removeClass('is-active');
			const href = $(e.currentTarget).attr('href');
			const hash = href.substr(href.indexOf('#'));
			bodyScrollBar.scrollIntoView(document.querySelector(hash), {
				offsetTop: 80
			});
		});

		if (window.location.href.indexOf('#') > -1) {
			const {
				href
			} = window.location;
			const hash = href.substr(href.indexOf('#'));
			setTimeout(() => {
				bodyScrollBar.scrollIntoView(document.querySelector(hash), {
					offsetTop: 80
				});
			}, 600);
		}

		$('.-scroll-down').on('click', () => {
			bodyScrollBar.scrollIntoView(document.querySelector('section').nextElementSibling, {
				offsetTop: 80
			});
		});
	}
}

module.exports = {
	initScroll,
	scrollToTop,
	anchorLinks
};
